import {
  TIPS,
  REGISTER,
  SAVERATING,
  REFRESH_LOGIN,
  LOGIN,
  REALM_ITEM,
  REALM_DATA,
  DIFF,
  ACTIVE_USERS,
  TRANSCRIPTS_CLAIM,
  DOWNLOAD_ATTACHMENT,
  DOWNLOAD_INPUT,
  UPDATE_MEDIA,
  SETTING,
  SECOND_REVIEWS,
  PRE_ORDER,
  OFFLINE_LOGIN,
  STEREO_AUDIO,
  TWO_FACTOR,
  TWO_LOGIN,
  PRE_ORDER_SETTING,
  USER_INFO,
  UPDATE_USER_INFO,
  ALIGN_TRANSCRIPT,
  MACHINE_TRANSCRIPT,
  RESET_TRANSCRIPT,
  DELETE_TRANSCRIPT,
  PRE_ORDERS,
  RUNTIME_LOGS,
  NOTIFY_COMPLETION,
  ARCHIVE_ORDERS,
  ARCHIVE_ORDER,
  MERGE_INPUTS,
  INPUT,
  ORDER,
  USER_VALIDATE,
  SEARCH_INPUT,
  UPDATE_ORDER_TYPE,
  USERS,
  PREASSIGN,
  POSTASSIGN,
  TRANSFER,
  WORKFLOW,
  USER_STAGES,
  DELETE_USER,
  UPDATE_USER,
  RESEND_ORDER,
  UNARCHIVE_ALL,
  ARCHIVE_ALL,
  UNARCHIVE_BY_ID,
  ARCHIVE_BY_ID,
  USERS_EMAIL_NAMES,
  GET_FEATURES,
  ARCHIVE_WORKFLOW,
} from ".";
import template from "lodash/fp/template";
import axiosClient from "../middleware/axiosClient";
import { getServerObj } from "../utils/misc";
import { REALMS, SERVER_URL } from "../shared/consts";

const serverObj = getServerObj();

export const getTips = () => {
  return axiosClient.get(template(TIPS)(serverObj));
};

export const getFeatures = async () => {
  const response = await axiosClient(template(GET_FEATURES)(serverObj));
  const features = response.data.data.reduce(
    (allFeatures, feature) => ({ ...allFeatures, [feature.name]: feature.value }),
    {}
  );

  return features;
};

export const registerUser = payload => {
  return axiosClient.post(template(REGISTER)(serverObj), payload);
};

export const saveWorkRating = payload => {
  return axiosClient.post(template(SAVERATING)(serverObj), payload);
};
export const login = payload => {
  return axiosClient.post(template(LOGIN)(serverObj), payload);
};

export const validateUser = payload => {
  return axiosClient.post(template(USER_VALIDATE)(serverObj), payload);
};

export const refreshLogin = payload => {
  return axiosClient.post(template(REFRESH_LOGIN)(serverObj), payload);
};

export const updateRealmData = (realm, id, payload) => {
  return axiosClient.post(template(REALM_ITEM)({ ...serverObj, realm, id }), payload);
};

export const unclaimOrder = async (id, stage) => {
  try {
    const res = await axiosClient.post(`${SERVER_URL}/${REALMS.INPUT}s/unclaim/${id}`, { stage });

    return res;
  } catch (e) {
    alert(e.message);
  }
};

export const removeRatingInfo = async (id, stage) => {
  try {
    const res = await axiosClient.post(`${SERVER_URL}/${REALMS.USER}/removerating/${id}`, { stage });

    return res;
  } catch (e) {
    alert(e.message);
  }
};

export const removeRealmData = (realm, id) => {
  return axiosClient.delete(template(REALM_ITEM)({ ...serverObj, realm, id }));
};

export const fetchRealmData = (realm, id) => {
  return axiosClient.get(template(REALM_ITEM)({ ...serverObj, realm, id }));
};

export const fetchAllRealmData = (realm, params) => {
  return axiosClient.get(template(REALM_DATA)({ ...serverObj, realm }), {
    params,
  });
};

export const getDiff = (id, payload) => {
  return axiosClient.post(template(DIFF)({ ...serverObj, id }), payload);
};

export const getActiveUsers = () => {
  return axiosClient.get(template(ACTIVE_USERS)(serverObj));
};

export const getClaimData = () => {
  return axiosClient.get(template(TRANSCRIPTS_CLAIM)(serverObj));
};

export const downloadAttachment = id => {
  return axiosClient.get(template(DOWNLOAD_ATTACHMENT)({ ...serverObj, id }));
};

export const downloadInput = id => {
  return axiosClient.get(template(DOWNLOAD_INPUT)({ ...serverObj, id }));
};

export const updateMedia = id => {
  return axiosClient.put(template(UPDATE_MEDIA)({ ...serverObj, id }));
};

export const getSettings = () => {
  return axiosClient.get(template(SETTING)(serverObj));
};

export const postSettings = payload => {
  return axiosClient.post(template(SETTING)(serverObj), payload);
};

export const fetchSecondReviews = () => {
  return axiosClient.get(template(SECOND_REVIEWS)(serverObj));
};

export const fetchPreOrders = () => {
  return axiosClient.get(template(PRE_ORDER)(serverObj));
};

export const loginOffline = payload => {
  return axiosClient.post(template(OFFLINE_LOGIN)(serverObj), payload);
};

export const fixMonoAudio = id => {
  return axiosClient.post(template(STEREO_AUDIO)({ ...serverObj, id }));
};

export const twoFactorSignup = payload => {
  return axiosClient.post(template(TWO_FACTOR)(serverObj), payload);
};

export const googleLogin = payload => {
  return axiosClient.post(template(TWO_LOGIN)(serverObj), payload);
};

export const getPreOrderSetting = () => {
  return axiosClient.get(template(PRE_ORDER_SETTING)(serverObj));
};

export const getUserInfo = params => {
  return axiosClient.get(template(USER_INFO)(serverObj), { params });
};

export const updateUserInfo = (email, payload) => {
  return axiosClient.post(template(UPDATE_USER_INFO)({ ...serverObj, email }), payload);
};

export const postAlignTranscript = id => {
  return axiosClient.post(template(ALIGN_TRANSCRIPT)({ ...serverObj, id, realm: REALMS.ATTACHMENT }));
};

export const postTriggerMachineTranscript = id => {
  return axiosClient.post(template(MACHINE_TRANSCRIPT)({ ...serverObj, id, realm: REALMS.ATTACHMENT }));
};

export const postResetTranscript = id => {
  return axiosClient.post(template(RESET_TRANSCRIPT)({ ...serverObj, id, realm: REALMS.ATTACHMENT }));
};

export const deleteTranscript = id => {
  return axiosClient.delete(template(DELETE_TRANSCRIPT)({ ...serverObj, id, realm: REALMS.ATTACHMENT }));
};

export const getPreorders = () => {
  return axiosClient.get(template({ PRE_ORDERS })({ ...serverObj, realm: REALMS.PREORDER }));
};

export const postLogError = payload => {
  return axiosClient.post(template(RUNTIME_LOGS)(serverObj), payload);
};

export const postNotifyCompletion = id => {
  return axiosClient.post(template(NOTIFY_COMPLETION)({ ...serverObj, id }));
};

export const postArchiveAllOrders = () => {
  return axiosClient.post(template(ARCHIVE_ORDERS)(serverObj));
};

export const postArchiveOrder = id => {
  return axiosClient.post(template(ARCHIVE_ORDER)({ ...serverObj, id }));
};

export const postMergeInput = id => {
  return axiosClient.post(template(MERGE_INPUTS)({ ...serverObj, id }));
};

export const postInput = payload => {
  return axiosClient.post(template(INPUT)(serverObj), payload);
};

export const postOrder = payload => {
  return axiosClient.post(template(ORDER)(serverObj), payload);
};

export const searchTranscript = payload => {
  return axiosClient.post(template(SEARCH_INPUT)(serverObj), payload);
};

export const updateOrderType = (id, orderType) => {
  return axiosClient.post(template(UPDATE_ORDER_TYPE)({ ...serverObj, id }), { orderType });
};

export const getUsers = () => {
  return axiosClient.get(template(USERS)(serverObj));
};

export const getUsersEmailAndNames = () => {
  return axiosClient.get(template(USERS_EMAIL_NAMES)(serverObj));
};

export const preassign = payload => {
  return axiosClient.post(template(PREASSIGN)(serverObj), payload);
};

export const postassign = payload => {
  return axiosClient.post(template(POSTASSIGN)(serverObj), payload);
};

export const transferAssign = payload => {
  return axiosClient.post(template(TRANSFER)(serverObj), payload);
};

export const getInputData = id => {
  return axiosClient.get(template(WORKFLOW)({ ...serverObj, id }));
};

export const getArchivedInputData = id => {
  return axiosClient.get(template(ARCHIVE_WORKFLOW)({ ...serverObj, id }));
};

export const setAllowedStages = (id, payload) => {
  return axiosClient.post(template(USER_STAGES)({ ...serverObj, id }), payload);
};

export const deleteUser = id => {
  return axiosClient.delete(template(DELETE_USER)({ ...serverObj, id }));
};

export const updateUser = (id, payload) => {
  return axiosClient.patch(template(UPDATE_USER)({ ...serverObj, id }), payload);
};

export const archiveOrdersById = id => {
  return axiosClient.post(template(ARCHIVE_BY_ID)({ ...serverObj, id }));
};

export const unarchiveOrdersById = id => {
  return axiosClient.post(template(UNARCHIVE_BY_ID)({ ...serverObj, id }));
};

export const archiveAllOrders = () => {
  return axiosClient.post(template(ARCHIVE_ALL)(serverObj));
};

export const unarchiveRecentOrders = () => {
  return axiosClient.post(template(UNARCHIVE_ALL)(serverObj));
};

export const resendOrder = (id, currentDB) => {
  return axiosClient.post(template(RESEND_ORDER)({ ...serverObj, id }), { currentDB });
};
