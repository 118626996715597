import axios from "axios";
import NProgress from "nprogress";
import { getCookie } from "../utils/misc";
import { SERVER_URL } from "../shared/consts";

const THRESHOLD_FOR_INACTIVE_LOGOUT_IN_MS = 1 * 24 * 60 * 60 * 1000; // one day of inactivity

NProgress.configure({
  minimum: 0.1,
  easing: "ease",
  speed: 500,
  showSpinner: false,
});

export { NProgress };

const axiosClient = axios.create({});
const TOKEN_EXPIRY_PLAY_IN_MIN = 5;

axiosClient.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
axiosClient.defaults.validateStatus = function validateStatus(status) {
  return status >= 200 && status <= 299; // default
};

function parseJwt(token) {
  try {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    alert(e.message);
  }
}

const isValidToken = token => {
  try {
    const { exp } = parseJwt(token);

    return exp > Date.now() / 1000 + TOKEN_EXPIRY_PLAY_IN_MIN * 60;
  } catch (e) {
    return false;
  }
};

const tokenShowsInactivity = (token, thresholdInMS) => {
  try {
    const { exp } = parseJwt(token);

    return Date.now() - exp * 1000 > thresholdInMS;
  } catch (e) {
    return true;
  }
};

axiosClient.defaults.headers.common["desktoptoken"] = getCookie("desktoptoken");

axiosClient.interceptors.request.use(
  async config => {
    NProgress.start();
    localStorage.setItem("lastReqTime", Date.now());
    const accessToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    try {
      // if there is no token available, user is yet to sign in
      if (!accessToken && !refreshToken) return config;

      if (accessToken && isValidToken(accessToken)) {
        if (tokenShowsInactivity(accessToken, THRESHOLD_FOR_INACTIVE_LOGOUT_IN_MS)) {
          throw new axios.Cancel("Account inactivity detected. Please log in again.");
        }

        config.headers.common["token"] = localStorage.getItem("token");

        return config;
      }

      if (refreshToken && isValidToken(refreshToken)) {
        try {
          const response = await axios.post(`${SERVER_URL}/token`, { refreshToken });

          const { token, email } = response.data;

          localStorage.setItem("token", token);
          localStorage.setItem("email", email);
          config.headers.common["token"] = localStorage.getItem("token");

          return config;
        } catch (e) {
          throw new axios.Cancel("Error while getting new token.");
        }
      }

      throw new axios.Cancel("Your session has expired. Please login again.");
    } catch (e) {
      alert(e.message);
      localStorage.clear();
      window.location.reload();
    }
  },

  () => {
    // Do something with request error
    NProgress.done();
  }
);
axiosClient.interceptors.response.use(
  response => {
    // Do something before request is sent

    if (response.config.url !== response.request.responseURL) {
      window.location.href = "/maintenence";

      return;
    }

    NProgress.done();

    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }

    NProgress.done();

    return Promise.reject(error);
    // Do something with request error
  }
);

export default axiosClient;
