/* eslint-disable no-template-curly-in-string */

export const TIPS = "${serverUrl}/tips";
export const REGISTER = "${serverUrl}/user/register";
export const SAVERATING = "${serverUrl}/user/saverating";
export const LOGIN = "${serverUrl}/user/login";
export const USER_VALIDATE = "${serverUrl}/user/validate";
export const REFRESH_LOGIN = "${serverUrl}/token";
export const REALM_ITEM = "${serverUrl}/_${realm}s/${id}";
export const REALM_DATA = "${serverUrl}/_${realm}s";
export const DIFF = "${serverUrl}/diff/${id}/diff";
export const ACTIVE_USERS = "${serverUrl}/user/active";
export const TRANSCRIPTS_CLAIM = "${serverUrl}/inputs/transcripts-claim";
export const DOWNLOAD_ATTACHMENT = "${serverUrl}/download/attachment/${id}";
export const DOWNLOAD_INPUT = "${serverUrl}/download/input/${id}";
export const UPDATE_MEDIA = "${serverUrl}/inputs/updateMedia/${id}";
export const SETTING = "${serverUrl}/settings";
export const SECOND_REVIEWS = "${serverUrl}/inputs/second-reviews";
export const PRE_ORDER = "${serverUrl}/preorder";
export const OFFLINE_LOGIN = "${serverUrl}/user/dev-login";
export const STEREO_AUDIO = "${serverUrl}/media/${id}/stereo";
export const TWO_LOGIN = "${serverUrl}/user/login/2fa";
export const TWO_FACTOR = "${serverUrl}/user/signup/2fa";
export const PRE_ORDER_SETTING = "${serverUrl}/preorder/settings";
export const USER_INFO = "${serverUrl}/user/info";
export const UPDATE_USER_INFO = "${serverUrl}/user/info/${email}";
export const ALIGN_TRANSCRIPT = "${serverUrl}/${realm}s/${id}/align";
export const MACHINE_TRANSCRIPT = "${serverUrl}/${realm}s/${id}/trigger/machine-transcript";
export const RESET_TRANSCRIPT = "${serverUrl}/${realm}s/${id}/trigger/reset-transcript";
export const DELETE_TRANSCRIPT = "${serverUrl}/${realm}s/${id}";
export const PRE_ORDERS = "${serverUrl}/${realm}s";
export const RUNTIME_LOGS = "${serverUrl}/runtimeLogs";
export const NOTIFY_COMPLETION = "${serverUrl}/orders/${id}/notifyCompletion";
export const ARCHIVE_ORDERS = "${serverUrl}/orders/archiveAll";
export const ARCHIVE_ORDER = "${serverUrl}/orders/${id}/archive";
export const MERGE_INPUTS = "${serverUrl}/orders/${id}/merge";
export const INPUT = "${serverUrl}/inputs";
export const ORDER = "${serverUrl}/orders";
export const SEARCH_INPUT = "${serverUrl}/inputs/search";
export const UPDATE_ORDER_TYPE = "${serverUrl}/inputs/resetOrderType/${id}";
export const USERS = "${serverUrl}/_users";
export const USERS_EMAIL_NAMES = "${serverUrl}/user/allUsersEmailandName";
export const PREASSIGN = "${serverUrl}/inputs/preassign";
export const POSTASSIGN = "${serverUrl}/inputs/postassign";
export const TRANSFER = "${serverUrl}/inputs/transfer";
export const WORKFLOW = "${serverUrl}/inputs/workflow/${id}";
export const ARCHIVE_WORKFLOW = "${serverUrl}/inputs/workflow/${id}/archive";
export const USER_STAGES = "${serverUrl}/user/userStages/${id}";
export const DELETE_USER = "${serverUrl}/user/${id}";
export const UPDATE_USER = "${serverUrl}/user/update/${id}";
export const ARCHIVE_BY_ID = "${serverUrl}/inputs/archive/${id}";
export const UNARCHIVE_BY_ID = "${serverUrl}/inputs/unarchive/${id}";
export const ARCHIVE_ALL = "${serverUrl}/inputs/archiveAll";
export const UNARCHIVE_ALL = "${serverUrl}/inputs/unarchiveRecent";
export const RESEND_ORDER = "${serverUrl}/inputs/resend/${id}";
export const GET_FEATURES = "${serverUrl}/features";
